import React, {forwardRef, useEffect, useRef, useState} from 'react';
import Autocomplete from 'react-autocomplete';
import {Form, Icon, Input, Radio, Select, TextArea} from "semantic-ui-react";
import {objectFind} from "../../helpers/jsHelper";

export const WInput = forwardRef(({icon='',fieldClassName='', required=false,className=null,limit=1000, label = null,type='text', errorData = [], multiline = false, name = null, defaultData = {}, onChange = null, ...props }, ref) => {
     const [value, setValue] = useState('');
     const [errorText, setErrorText] = useState('');
     
     className = className === false ? '': `form-control ${className}`;
     
     useEffect(() => {
        if (defaultData?.[name]) {
           setValue(defaultData[name]);
        }
     }, [defaultData]);
     
     useEffect(() => {
        if (errorData?.[name]) {
           setErrorText(errorData[name]);
        } else {
           setErrorText('');
        }
     }, [errorData]);
     
     const handleChange = ({ target }) => {
        const { value: val = '' } = target;
        if (val.length <= limit){
           setValue(type === 'number' ? parseFloat(val) : val);
           onChange?.({ name, value: type === 'number' ? Number(val) : val });
        }
     };
     
     return (
       <Form.Field className={fieldClassName}>
          {label !== false && <label>{label}</label>}
          {multiline ?
            <TextArea required={required} className={` ${className} ${errorText && 'is-invalid'}`} name={name} value={value} onChange={handleChange} {...props}/> :
              <Input required={required} type={type} ref={ref} className={`${className} ${errorText && 'is-invalid'}`} name={name} value={value} onChange={handleChange} {...props}>
                  {icon && <Icon name={icon}/>}<input/>
              </Input>}
          {errorText && <div className="invalid-feedback">{errorText}</div>}
       </Form.Field>
     );
  });

export const WTextArea = (props) => <WInput multiline {...props}/>;

// export const ReCaptcha = forwardRef(({ name = null, onChange }, ref) => {
//    const handleChange = (val) => {
//       onChange?.({ name, value: val });
//    };
//    return <ReCAPTCHA ref={ref} sitekey={CAPTCHA_KEY} onChange={handleChange}/>;
// });


export const WSelect = forwardRef(
  ({ className='',find='',plain=false,add=null, label = null,emptyText = "-select-",fullWidth=false, name = null, defaultData = {}, onChange = null,optionClassName="", options = [], ...props }, ref) => {
     const [value, setValue] = useState(props.multiple ? []:'');

     className = fullWidth ? className + ' ' + 'full-width' : className;
     useEffect(() => {
        if (defaultData?.[name]) {
            if (find){
                let res = defaultData[name];
                res = find ? (props?.multiple ? res.map(a=>a[find]).filter(a=>!!a) : res[find]) : res;

                setValue(res)
                return
            }
           setValue(defaultData[name]);
        }
     }, [defaultData]);
     
     const handleChange = (a,target) => {
        const { value: val = null } = target;
        setValue(val);
        let res = props?.multiple ? val.map(a=> objectFind(options,'_id',a)) : objectFind(options,'_id',val)
        onChange?.({ name, value:plain ? val : res  });
     };
     return (
       <Form.Field>
          <label>{label} {add && add}</label>
          <Select ref={ref} selectOnBlur={false} className={className} name={name} options={options} value={value} onChange={handleChange} {...props} />
       </Form.Field>
     );
  });


export const PlainInput = forwardRef(({ required=false,className=null,limit=1000, label = null,type='text', errorData = [], multiline = false, name = null, defaultData = {}, onChange = null, ...props }, ref) => {
    const [value, setValue] = useState('');
    const [errorText, setErrorText] = useState('');

    className = className === false ? '': `form-control ${className}`;

    useEffect(() => {
        if (defaultData?.[name]) {
            setValue(defaultData[name]);
        }
    }, [defaultData]);

    useEffect(() => {
        if (errorData?.[name]) {
            setErrorText(errorData[name]);
        } else {
            setErrorText('');
        }
    }, [errorData]);

    const handleChange = ({ target }) => {
        const { value: val = '' } = target;
        if (val.length <= limit){
            setValue(type === 'number' ? parseFloat(val) : val);
            onChange?.({ name, value: type === 'number' ? Number(val) : val });
        }
    };

    return (
        <>
            {label !== false && <label>{label}</label>}
            {multiline ?
                <textarea required={required} className={` ${className} ${errorText && 'is-invalid'}`} name={name} value={value} onChange={handleChange} {...props}/> :
                <input required={required} type={type} ref={ref} className={`${className} ${errorText && 'is-invalid'}`} name={name} value={value} onChange={handleChange} {...props}/>}
            {errorText && <div className="invalid-feedback">{errorText}</div>}
        </>
    );
});

export const PlainTextArea = (props) => <Input multiline {...props}/>;


export const AutoCompleteInput = ({ valid=false,defaultValue=null, wrapperStyle={},className=null,inputProps={},clearSelect=false,fullWidth=false,wrapperProps={}, items = [], min = 3, name = null, onChange = null }) => {
   const [options, setOptions] = useState(items);
   const [open, setOpen] = useState(false);
   const [value, setValue] = useState();
   wrapperProps['className'] = wrapperProps['className'] ?? ' ' +' '+(fullWidth ? 'full-width' : '')
    useEffect(()=>{
        setValue(defaultValue)
    },[defaultValue])
   const handleChange = ({ target }) => {
      const { value = '' } = target;
      let opt = items.filter(({ label }) => label.toLowerCase().indexOf(value.toLowerCase()) > -1);
      setOptions(opt);
      setValue(value);
      if (value.trim() && value.length > min && opt.length) {
         setOpen(true);
      } else {
         if (open) {
            setOpen(false);
         }
      }
      if (!valid) {
         onChange?.({ name, value: value });
       }
   };
   const handleSelect = (val) => {
      setOpen(false);
      onChange?.({ name, value: val });
      if (clearSelect) {
         val = '';
      }
      setValue(val);
   };
   return (
     <Autocomplete
       getItemValue={(item) => item.label}
       items={options}
       open={open}
       wrapperProps={wrapperProps}
       inputProps={{
          className,
          onBlur:()=>setOpen(false),
           ...inputProps
       }}
       renderItem={(item, isHighlighted) =>
         <div className={'font-fixer sizer pointer'} key={item.label}
              style={{ background: isHighlighted ? '#eee' : 'white' ,color:'#222',padding:10,textAlign:'left'}}>
            {item.label}
         </div>
       }
       value={value}
       wrapperStyle={wrapperStyle}
       onChange={handleChange}
       onSelect={handleSelect}
     />
   );
};

export const RadioButton = ({ label = null,successValue=true,checked=null, name = null, onChange = null, defaultData = {}, ...props }) => {
   const ref = useRef();
   
   const handleChange = ({ target: { checked } }) => {
      onChange?.({ name, value: checked ? successValue : false });
   };
   useEffect(() => {
      if (defaultData?.[name]) {
         let check = defaultData[name] === successValue;
         ref.current.checked = check
         handleChange({target:{checked:check}})
      }
   }, [defaultData]);
   
   useEffect(() => {
      if (checked !== null) {
         ref.current.checked = checked;
         handleChange({target:{checked}})
      }
   }, [checked])
   
   return <Radio label={label} ref={ref} onChange={handleChange} name={name}/>
}

export const Checkbox = ({successValue=true,label='',name=null,className='',defaultData={},onChange=null})=>{
   const [checked, setChecked] = useState(false)

   useEffect(() => {
   
      if (defaultData?.[name]) {
         setChecked(defaultData[name]);
      }
   }, [defaultData]);

   const handleChange = ()  => {
      let check = !checked;
      setChecked(check);
      onChange?.({ name, value: check && successValue });
   };

   return (
     <>
        <input className={`styled-checkbox ${className}`} type="checkbox" onChange={handleChange} checked={checked} name={name} value={successValue}/>
        <label onClick={handleChange}>{label}</label>
     </>
   );
};

export const RadioCheckbox = ({successValue=true,label='',checked,name=null,className='',defaultData={},onChange=null})=>{

    const handleChange = ()  => {
       onChange?.({ name, value: successValue });
    };
 
    return (
      <>
         <input className={`styled-checkbox ${className}`} type="radio" checked={checked === successValue} onChange={handleChange} name={name} value={successValue}/>
         <label onClick={handleChange}>{label}</label>
      </>
    );
 };

 export const CusRadioCheckbox  = ({successValue=true,label='',name=null,className='',nameDefault='',defaultData={},onChange=null})=>{
    const [checked, setChecked] = useState(false)

  
    const handleChange = (e)  => {
       onChange?.({ name, value: e.target.checked });
    };
  
    return (
      <>
         <input id={name} className={`styled-checkbox ${className}`} type="radio" onChange={handleChange} name={nameDefault} value={successValue}/>
         <label for={name}>{label}</label>
      </>
    );
  };

export const CInput = forwardRef(({maskPlaceholder = null, mask = null, required = false, className = null, limit = 1000, label = null, type = 'text', errorData = [], multiline = false, name = null, defaultData = {}, onChange = null, ...props}, ref,) => {
        const [value, setValue] = useState('');
        const [errorText, setErrorText] = useState('');

        className = className === false ? '' : `form-control ${className}`;
        useEffect(() => {
            if (maskPlaceholder) {
                setValue(maskPlaceholder);
            }
        }, [maskPlaceholder]);
        useEffect(() => {
            if (defaultData?.[name]) {
                setValue(defaultData[name]);
            }
        }, [defaultData]);

        useEffect(() => {
            if (errorData?.[name]) {
                setErrorText(errorData[name]);
            } else {
                setErrorText('');
            }
        }, [errorData]);
        function currencyFormat(num) {
            return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
        const handleChange = ({ target }) => {
            const { value: val = '' } = target;
            if (val.length <= limit) {
                setValue(type === 'number' ? parseFloat(val) : val);
                onChange?.({ name, value: type === 'number' ? val : val });
            }
        };

        return (
            <>
                {label !== false && <label>{label}</label>}
                {multiline ? (
                    <textarea required={required} className={` ${className} ${errorText && 'is-invalid'}`} name={name} value={value} onChange={handleChange}{...props}/>
                ) : (
                    <>
                        <input required={required} type={type} ref={ref} className={`${className} ${errorText && 'is-invalid'}`} name={name} value={value} onChange={handleChange}{...props}/>
                        {errorText && <div className="invalid-feedback">{errorText}</div>}
                    </>
                )}
            </>
        );
    },
);


export const TSearch = ({loading=false,onChange=null,onSearch=null})=>{
    const [value,setValue]=useState();
    const handleChange = ({target:{value}})=>{
        onChange?.(value)
        setValue(value)
    }
    return (
        <Input action={{
            icon: 'search',
            loading,
            onClick:()=>onSearch(value)}} size={"small"}  onChange={handleChange} value={value} placeholder='Search...' />
    )
}